<template>
  <v-card flat>
    <v-card-title>
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        dense
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        :to="{ path: '/gestor/dados/equipe/adicionar' }"
        absolute
        right
        color="secondary"
        class="white--text"
        >Adicionar
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        v-if="equipe"
        :headers="headers"
        :search="search"
        :items="equipe"
        :items-per-page="10"
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="goToEquipeIndividuo"
      >
        <template v-slot:item.responsavel="{ item }">
          {{ item.responsavel ? "Sim" : "Não" }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status | status }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { fetchEquipe } from "@/api/gestor/empresa_equipe.js";
export default {
  name: "ListaEquipe",

  data() {
    return {
      equipe: [],
      search: "",
      display: false,
      loading: true,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Cargo",
          value: "cargo.descricao",
        },
        {
          text: "Responsavel",
          value: "responsavel",
        },
        {
          text: "Status",
          sortable: false,
          align: "center",
          value: "status",
        },
      ];
    },
  },

  methods: {
    goToEquipeIndividuo(item) {
      this.$router.push({
        path: `/gestor/dados/equipe/${item.id}`,
      });
    },

    getEquipe() {
      this.loading = true;
      fetchEquipe()
        .then((response) => {
          this.equipe = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getEquipe();
  },
};
</script>

<style scoped lang="scss"></style>
